import { IAppGlobalState } from "../interfaces/context/IAppGlobalState";

type globalAction = { type: 'setGlobalState', payload: IAppGlobalState };

export const GlobalReducer = (state: IAppGlobalState, action: globalAction): IAppGlobalState => {
    switch(action.type){
        case 'setGlobalState':
            return action.payload;
        default:
            return state;
    }
}