import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import preview from '../../assets/images/preview.png';
import { GlobalContext } from "../../context/GlobalContext";
import useStorage from "../../helpers/useStorage/useStorage";
import { IResponseTorak } from "../../interfaces/context/IResponseTorak";


const PresentationPage = () => {
    const { getJSON } = useContext(GlobalContext);
    
    const isLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);
    return(
        <Container style={{paddingTop: '85px'}}>
            <Row>
                <Col>
                    <Image src={grayHeart} className="heart_image_gray"/>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h2 style={{textAlign: "center", fontWeight: "bold"}}>Introducción</h2>
                    <p>
                        El estrecho vínculo que existe entre “corazón” y “emoción” es una tradición
                        universal. Esa estructura oculta dentro del tórax ha ejercido desde el origen del
                        hombre, una fascinación que ha fluctuado entre la magia, el sentimiento y la
                        conciencia.
                    </p>
                    <p>
                        El cuerpo es ejemplo de la precisión del movimiento y ese movimiento está
                        generado por el corazón. Conocer su cuerpo es conocer su mapa, le ayudará a
                        saber cómo está, que tan sano o enfermo se encuentra y saber si va a vivir
                        más tiempo o no.
                    </p>
                    <p>
                        En este libro tratamos de comunicar la importancia de atender y cuidar su
                        corazón, si por desgracia ya no funciona bien, le recomendaremos como
                        trabajar mejor ese “motorcito” que impulsa la sangre a todo su cuerpo y que
                        mientras bombee la sangre a nuestras células, seguirá conservando lo más
                        precioso que se nos ha dado que es la vida.
                    </p>
                    <p>
                        La veneración del corazón tiene un profundo sentido porque lo consideran el
                        órgano sagrado en diversas culturas, de ahí que la definición de muerte se
                        conocería en México hasta 1982 con la presencia de paro cardiaco. Desde
                        entonces y con base a la legislación de trasplante de órganos, se estableció
                        que para autentificar el diagnóstico de muerte debía ser cuando existe daño
                        neurológico irreversible, a pesar de que el corazón siguiera latiendo.
                    </p>
                    <p>
                        En los últimos años, se ha observado un avance impresionante en los
                        conocimientos, en la tecnología y en la inteligencia artificial aplicada a la
                        medicina, en especial a la cardiología, lo que ha modificado radicalmente las
                        formas de diagnosticar y dar tratamiento.
                    </p>
                    <p>
                        La sensación primaria que percibimos del corazón es que éste “salta”, ya sea
                        con los esfuerzos o con las emociones y esto se comprueba en algunas
                        expresiones cotidianas. Pero la mayor parte del tiempo, no percibimos el gran
                        trabajo de este órgano.
                    </p>
                    <p>
                        Muchas veces la percepción del latido cardiaco, las palpitaciones, la sensación
                        de no respirar bien o el dolor es lo que el paciente interpreta para decir que no
                        está funcionando bien su corazón y busca atención médica, pero existe una
                        frase muy mencionada dentro del ámbito médico que dice “el corazón no tiene
                        palabra de honor”, así que los problemas cardiovasculares pueden presentarse
                        de forma inesperada, sin ningún aviso previo, e incluso en ocasiones la primera
                        manifestación de una enfermedad cardiovascular es la muerte súbita. Por ello,
                        la prevención de las enfermedades cardiovasculares es una acción prioritaria.
                    </p>
                    <p>
                        El propósito de esta obra es presentar con la mayor facilidad y sencillez de
                        manera escrita y visual la importancia de mantener una buena salud del
                        corazón y, si éste se ha deteriorado, como podemos cuidarlo, tratarlo y
                        rehabilitarlo.
                    </p>
                    <p>
                        De manera específica, tratamos de ayudar al paciente y a sus familiares de una
                        forma muy práctica, con un lenguaje ameno y, con imágenes y videos sencillos,
                        cómo funciona un corazón, por qué se enferma y qué puede hacer para
                        mantener o recuperar su salud.
                    </p>
                    <p>
                        De ahí que un grupo selecto de médicos líderes de opinión, se dieron a la tarea
                        de publicar este libro, el cual dirigido a cualquier persona que se interese por
                        mantener sano su corazón.
                    </p>
                    <p style={{textAlign: "right", marginBottom: "50px"}}>
                        Los coautores
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default PresentationPage;