import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext";

export const Chapter5
 = () => {
    const { getJSON } = useContext(GlobalContext);
    
    const isLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);
    return (
        <div></div>
    );
}