import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import { GlobalContext } from '../../context/GlobalContext';
import useStorage from '../../helpers/useStorage/useStorage';
import { IResponseTorak } from '../../interfaces/context/IResponseTorak';

export const Chapter1 = () => {
    const [show, setShow] = useState(false);
    const [file, setFile] = useState("");
    const [title, setTitle] = useState("");
    const baseUrl = "https://api.cuidatucorazon.com.mx/service/multimedia/getFile?file=";

    const { getJSON } = useContext(GlobalContext);
    
    const isLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);


    const showFile = (name: string, type: "mp4" | "pdf", title: string) => {
        if(type === "mp4"){
            setTitle(title);
            setFile(require("../../assets/multimedia/" + name));
            setShow(true);
        }
    }

    return (
        <Container className="chapter" fluid  style={{paddingTop: '85px', backgroundColor: '#e7b219',paddingBottom:'120px'}}>
            <Container>
                <Row>
                    <Col>
                        <Image src={grayHeart} className="heart_image_gray"/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{display: "table"}}>
                        <Link to="/indexmedia" title='regresar'>
                            <span style={{width: "50px", marginRight: '10px'}}>
                                <FontAwesomeIcon style={{color: '#212529', paddingRight: '1px'}} icon={faCirclePlay as IconProp} size="2x"/>
                            </span>
                            <span style={{color: "#FFF", fontWeight: "bold", verticalAlign: "middle", display: "table-cell"}}>
                                Material audiovisual
                            </span>
                        </Link>
                    </Col>
                </Row>
                <Row style={{marginTop: "20px"}}>
                    <Col md={2} xs={12} style={{zIndex: 99}}>
                        <h2 style={{fontWeight: "bold", textAlign: "center"}}>Capítulo <br/> 1</h2>
                    </Col>
                    <Col md={10} xs={12} className="chapterText" style={{zIndex: 99}}>
                        <h4>¿Cómo funciona mi corazón?</h4>
                        <span>Corazón "in vivo" latiendo normalmente <a href="#" onClick={() => showFile("C1_V1.mp4", "mp4", `Corazón "in vivo" latiendo normalmente`)} className="chapterFile">Video 1</a></span>
                        <span>Corte longitudinal del corazón <a href="#" onClick={() => showFile("C1_F3-1.mp4", "mp4", `Corte longitudinal del corazón`)} className="chapterFile">Video 2</a></span>
                        <span>Esquema de flujo sanguíneo cardiaco <a href="#" onClick={() => showFile("C1_F4-1.mp4", "mp4", `Esquema de flujo sanguíneo cardiaco`)} className="chapterFile">Video 3</a></span>
                        <span>Oclusión total de una arteria <a href="#" onClick={() => showFile("C1_V2-1.mp4", "mp4", `Oclusión total de una arteria`)} className="chapterFile">Video 4</a></span>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <video src={file} controls controlsList="nodownload" style={{height: "100%", width: "100%"}} autoPlay>
                        Your browser does not support the video tag.
                    </video>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
       </Container>
       
    );
}