import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import TemplateHTML from './templates/template';
import { GlobalProvider } from './context/GlobalContext';
import './App.css';

function App() {
  return (
    <GlobalProvider >
      <BrowserRouter>
        <TemplateHTML/>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
