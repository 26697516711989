import React, { createContext, useEffect, useReducer, useRef, useState } from "react";
import { GlobalReducer } from "./GlobalReducer";
import axios from "axios";
import { IAppGlobalState } from "../interfaces/context/IAppGlobalState";
import { Spinner, Toast } from "react-bootstrap";
import { IAppGlobalProps } from "../interfaces/context/IAppGlobalProps";

let baseURL = 'https://api.cuidatucorazon.com.mx/';
//let baseURL = 'http://localhost/Asecom/libro_digital/api/';

let module = "web";
const reqResApi = axios.create({
    baseURL: baseURL,
    timeout: 1000 * 40,
    timeoutErrorMessage: "El servidor demoró más de lo habitual por favor vuelve a intentar"
});

export const GlobalContext = createContext( {} as IAppGlobalProps);
export const GlobalInitialState: IAppGlobalState = {
    token: "",
    username: "",
    permissions: [],
    urlPhoto: "",
    displayName: ""
}

export const GlobalProvider = ({children} : any) => {

    const [ GlobalState, dispatch ] = useReducer(GlobalReducer, GlobalInitialState);
    const [ loading, setLoading ] = useState(false);
    const toast = useRef<any>(null);

    const setGlobalState = ( state: IAppGlobalState ) => {
        dispatch({ type: 'setGlobalState', payload: state});
        sessionStorage.setItem("globalState", JSON.stringify(state));
    }

    const getJSON = async (controller: string, action: string = '', params: any, myModule?: string) => {
        let error = "";
        let warning = "";

        let backData: any = {
            status: true,
            data: {}, 
            error: ''
        };

        let myParams = {
            ...params,
            token: GlobalState.token
        }
        
        const paramsJSON = JSON.stringify(myParams);

        setLoading(true);

        await reqResApi.put(`${myModule === undefined ? module : myModule}/${controller}/${action}`, paramsJSON, {
            "headers": {
                "content-type": "application/json",
            }
        })
        .then( (response: any) => {
            
            if(response.status === 200){

                if(response.data.status === undefined){
                    error = response.data;
                }
                else if(!response.data.status){
                    if(response.data.error === -1){
                        warning = "Sesión caducada";
                        
                        setGlobalState({
                            token: "",
                            username: "",
                            permissions: [],
                            urlPhoto: "",
                            displayName: ""
                        });

                        window.location.href = "/";
                    }
                    else{
                        error = `${response.data.error}`;
                    }    
                }
                else{
                    backData = response.data;
                }
            }
        })
        .catch( response => {
            error = `${response}`;
        }).finally(async () => {
            
            if(error !== ""){
                backData.status = false;
                backData.error = error;
            }

            setLoading(false);
        });

        return backData;
    }

    const sendData = async (controller: string, action: string = '', params: any, myModule?: string, type: 'formData' | 'post' = 'post') => {
        let error = "";
        let warning = "";

        let backData: any = {
            status: true,
            data: {}, 
            error: ''
        };

        setLoading(true);

        if(type === "formData"){
            let myParams: any;
            myParams = params as FormData;
            myParams.append("token", GlobalState.token);

            await reqResApi.post(`${myModule === undefined ? module : myModule}/${controller}/${action}`, myParams, {
                "headers": {
                    "content-type": "multipart/form-data",
                }
            })
            .then( (response: any) => {
                
                if(response.status === 200){
    
                    if(response.data.status === undefined){
                        error = response.data;
                    }
                    else if(!response.data.status){
                        if(response.data.error === -1){
                            warning = "Sesión caducada";
                            
                            setGlobalState({
                                token: "",
                                username: "",
                                permissions: [],
                                urlPhoto: "",
                                displayName: ""
                            });
                        }
                        else{
                            error = `${response.data.error}`;
                        }    
                    }
                    else{
                        backData = response.data;
                    }
                }
            })
            .catch( response => {
                error = `${response}`;
            }).finally(async () => {
                
                if(error !== ""){
                    backData.status = false;
                    backData.error = error;
                }
    
                setLoading(false);
            });
        }
        else{
            params.token = GlobalState.token;
            const paramsJSON = JSON.stringify(params);

            await reqResApi.put(`${myModule === undefined ? module : myModule}/${controller}/${action}`, paramsJSON, {
                "headers": {
                    "content-type": "multipart/form-data",
                }
            })
            .then( (response: any) => {
                
                if(response.status === 200){
    
                    if(response.data.status === undefined){
                        error = response.data;
                    }
                    else if(!response.data.status){
                        if(response.data.error === -1){
                            warning = "Sesión caducada";
                            
                            setGlobalState({
                                token: "",
                                username: "",
                                permissions: [],
                                urlPhoto: "",
                                displayName: ""
                            });
                        }
                        else{
                            error = `${response.data.error}`;
                        }    
                    }
                    else{
                        backData = response.data;
                    }
                }
            })
            .catch( response => {
                error = `${response}`;
            }).finally(async () => {
    
                if(warning !== ""){
                    showMessage('warn', warning);
                }
                else if (error !== ""){
                    showMessage('error', error);
                }
                
                if(error !== ""){
                    backData.status = false;
                    backData.error = error;
                }
    
                setLoading(false);
            });
        }
        
        setLoading(false);

        return backData;
    }

    const isAuthenticated = (): boolean => {
        let result = false;
        
        if(GlobalState.token !== undefined && GlobalState.token !== null && GlobalState.token !== ""){
            result = true;
        }
        return result;
    }

    const showMessage = (type: 'success' | 'warn' | 'error', body: string) => {
        if(toast.current){
            console.log(type);
            toast.current.show({
                severity: type, 
                summary: type === "success" ? "Éxito" : (type === "warn" ? "Cuidado" : "Error"), 
                detail: body, 
                life: 10000
            });
        }
    } 

    useEffect(() => {
        let stringGlobalState: string | null = "";
        let objectGS: IAppGlobalState = GlobalState;
        stringGlobalState = sessionStorage.getItem("globalState");
                
        if(stringGlobalState !== null && stringGlobalState !== ""){
            objectGS = JSON.parse(stringGlobalState!);
            setGlobalState(objectGS);
        }
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                setGlobalState: setGlobalState,
                getGlobalState: GlobalState,
                getJSON: getJSON,
                sendData: sendData,
                isAuthenticated: isAuthenticated,
                showMessage: showMessage
            }}
            >
            
            {loading && (
                <div style={{position: "absolute", zIndex: 999, height: "100%", width: "100%"}}>
                    <div style={{position: "absolute", left: "50%", top: "50%", WebkitTransform: "translate(-50%, -50%)", transform: "translate(-50%, -50%)"}}>
                        <Spinner animation={"border"} />
                    </div>
                </div>
            )}
            
            { children }      
        </GlobalContext.Provider>
    );
}