import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import {Document, Page, pdfjs} from 'react-pdf';
import { GlobalContext } from '../../context/GlobalContext';
import useStorage from '../../helpers/useStorage/useStorage';
import { IResponseTorak } from '../../interfaces/context/IResponseTorak';
import { Link } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Chapter10 = () => {
    const [show, setShow] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [numPages, setNumPages] = useState<null | number>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState("");
    const [fileVideo, setFileVideo] = useState("");
    const [title, setTitle] = useState("");
    const baseUrl = "https://api.cuidatucorazon.com.mx/service/multimedia/getFile?file=";


    const showFile = (name: string, type: "mp4" | "pdf", title: string) => {
        if(type === "mp4"){
            setTitle(title);
            setFileVideo(require("../../assets/multimedia/" + name));
            setShow(true);
        }else if(type === "pdf"){
            setTitle(title);
            setFile(require("../../assets/multimedia/" + name));
            
            setShowPDF(true);
        }
    }

    function onDocumentLoadSuccess({ numPages}: {numPages: number}) {
        setNumPages(numPages);
    }

    useEffect(() => {
        console.log(file);
    }, [file]);

    const { getJSON } = useContext(GlobalContext);
    
    const isLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);

    return (
        <Container className="chapter" fluid  style={{paddingTop: '85px', backgroundColor: '#e7b219',paddingBottom:'120px'}}>
            <Container>
                <Row>
                    <Col>
                        <Image src={grayHeart} className="heart_image_gray"/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{display: "table"}}>
                        <Link to="/indexmedia" title='regresar'>
                            <span style={{width: "50px", marginRight: '10px'}}>
                                <FontAwesomeIcon style={{color: '#212529', paddingRight: '1px'}} icon={faCirclePlay as IconProp} size="2x"/>
                            </span>
                            <span style={{color: "#FFF", fontWeight: "bold", verticalAlign: "middle", display: "table-cell"}}>
                                Material audiovisual
                            </span>
                        </Link>
                    </Col>
                </Row>
                <Row style={{marginTop: "20px"}}>
                    <Col md={2} xs={12} style={{zIndex: 99}}>
                        <h2 style={{fontWeight: "bold", textAlign: "center"}}>Capítulo <br/> 10</h2>
                    </Col>
                    <Col md={10} xs={12} className="chapterText" style={{zIndex: 99}}>
                        <h4>¿Cómo debo alimentarme para mantener sano mi corazón?</h4>
                        <div>Recetas. Cremas, sopas y pastas <a href="#" onClick={() => showFile("CremasSopasPastas.pdf", "pdf", `Recetas. Cremas, sopas y pastas`)} className="chapterFile">Archivo 1</a></div>
                        <div>Recetas. Platos fuertes <a href="#" onClick={() => showFile("PlatosFuertes.pdf", "pdf", `Recetas. Platos fuertes`)} className="chapterFile">Archivo 2</a></div>
                        <div>Recetas. Acompañamientos <a href="#" onClick={() => showFile("Acompaniamientos.pdf", "pdf", `Recetas. Acompañamientos`)} className="chapterFile">Archivo 3</a></div>
                        <div>Recetas. Postres <a href="#" onClick={() => showFile("Postres.pdf", "pdf", `Recetas. Postres`)} className="chapterFile">Archivo 4</a></div>
                        <div>Molletes de nopal con frijoles <a href="#" onClick={() => showFile("C10_V1.mp4", "mp4", `Molletes de nopal con frijoles`)} className="chapterFile">Video 1</a></div>
                        <div>Ensalada de garbanzo <a href="#" onClick={() => showFile("C10_V2-1.mp4", "mp4", `Ensalada de garbanzo`)} className="chapterFile">Video 2</a></div>
                        <div>Crema de zanahoria <a href="#" onClick={() => showFile("C10_V3.mp4", "mp4", `Crema de zanahoria`)} className="chapterFile">Video 3</a></div>
                        <div>Sopa de lentejas <a href="#" onClick={() => showFile("C10_V4.mp4", "mp4", `Sopa de lentejas`)} className="chapterFile">Video 4</a></div>
                        <div>Filete de pescado <a href="#" onClick={() => showFile("C10_V5-1.mp4", "mp4", `Filete de pescado`)} className="chapterFile">Video 5</a></div>
                        <div>Pan francés <a href="#" onClick={() => showFile("C10_V6.mp4", "mp4", `Pan francés`)} className="chapterFile">Video 6</a></div>
                        <div>Pastel de taza <a href="#" onClick={() => showFile("C10_V7-1.mp4", "mp4", `Pastel de taza`)} className="chapterFile">Video 7</a></div>   
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {file} */}
                    <video src={fileVideo} controlsList="nodownload" controls style={{height: "100%", width: "100%"}} autoPlay>
                        Your browser does not support the video tag.
                    </video>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPDF} onHide={() => setShowPDF(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{textAlign: "center", display: "block", marginBottom: "10px"}}><a href={file} target="_blank" ><span style={{color: "#000000", textDecoration: "underline"}}>Descargar</span></a></span>
                    <Document 
                        file={file} 
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="chapterPDF"
                    >
                        {Array.apply(null, Array(numPages)).map((page: any, index: number) =>
                            <Page pageNumber={index + 1} key={index} width={window.innerWidth * 0.60}/>
                        )}
                    </Document>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPDF(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
       </Container>
    );
}