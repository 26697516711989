import { Container, Row, Col, Image } from "react-bootstrap";
import Ruesga from '../../assets/images/foto_ruesga.jpg';
import Cossio from '../../assets/images/foto_cossio.png';
import Gaxiola from '../../assets/images/foto_gaxiola.jpg';
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import { IResponseTorak } from "../../interfaces/context/IResponseTorak";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import useStorage from "../../helpers/useStorage/useStorage";
import { useNavigate } from "react-router-dom";


const LeadersPage = () => {
    const { getJSON } = useContext(GlobalContext);

    const isLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);

    return (
        <Container fluid style={{backgroundColor: '#e7b219', paddingTop: '65px', paddingBottom: '80px'}}>
            <Row>
                <Col>
                    <Image src={grayHeart} className="heart_image_gray"/>
                </Col>
            </Row>
            <Row>
                
                <Col xs md={3}>
                    <p style={{fontSize: '45px', color: 'white', textAlign: 'right', marginBottom: '-15px'}}>
                        LÍDERES
                    </p>
                    <p style={{fontSize: '45px', color: 'white', textAlign: 'right'}}>
                        DE <i style={{color: 'black'}}>OPINIÓN</i>
                    </p>
                </Col>
            </Row>
            <Row style={{backgroundColor: '#e7b219', paddingTop: '25px', paddingBottom: '25px'}}>
                <Col md={4} style={{fontWeight: "bold", zIndex: 99}} >
                    <div style={{textAlign: "center"}}><Image src={Ruesga} style={{height: '200px', marginBottom: "20px"}} alt='Ruesga' /></div>
                    <p className="leaderTitle" style={{color: 'white', fontSize: '20px', textAlign: 'center'}}>Dr. Eugenio <i style={{color: 'black'}}>Ruesga</i> Zamora</p>
                    <p className="leaderTitle" style={{color: 'white', fontSize: '18px', textAlign: 'center', marginBottom: "30px"}}>Cardiólogo clínico e intervencionista</p>
                    <p style={{fontSize: '13px'}}>
                    Cardiólogo clínico e intervencionista del departamento de Cardiología del Centro Médico ABC CMDX <br/>
                    Profesor Titular de Cardiología de la Escuela Superior de Medicina del IPN <br/>
                    Member of American College of Physicians <br/>
                    Premio Nacional de Investigación Básica 1993 <br/>
                    Exsecretario del Consejo Mexicano de Cardiología <br/>
                    Exsecretario de la Asociación Nacional de Cardiólogos de México AC <br/>
                    Editor y autor de 3 libros de texto de Cardiología en Iberoamérica <br/>
                    </p>
                </Col>
                <Col md={4} style={{fontWeight: "bold", zIndex: 99}}>
                    <div style={{textAlign: "center"}}><Image src={Cossio} style={{height: '200px', marginBottom: "20px"}} alt='Cossio'/></div>
                    <p className="leaderTitle" style={{color: '#FFF', fontSize: '20px', textAlign: "center"}}>Dr. Jorge Eduardo <i style={{color: 'black'}}>Cossío</i> Aranda</p>
                    <p className="leaderTitle" style={{color: 'white', fontSize: '18px', textAlign: 'center', marginBottom: "30px"}}>Médico internista, cardiólogo  y hemodinamista</p>
                    <p style={{fontSize: '13px'}}>
                        Maestro y Doctor en Ciencias Médicas <br/>
                        Diplomado en Gestión de Instituciones de Salud <br/>
                        Presidente de la Sociedad Mexicana de Cardiología <br/>
                        Fellow American College of Cardiology <br/>
                        International Member of European Society of Cardiology <br/>
                        Investigador de Institutos Nacionales de Salud y del Sistema Nacional de Investigadores <br/>
                        Ex jefe del departamento de la Consulta Externa del Instituto Nacional de Cardiología <br/>
                    </p>
                </Col>
                <Col md={4} style={{fontWeight: "bold", zIndex: 99}} >
                    <div style={{textAlign: "center"}}><Image src={Gaxiola} style={{height: '200px', marginBottom: "20px"}} alt='Gaxiola'/></div>
                    <p className="leaderTitle" style={{color: 'white', fontSize: '20px', textAlign: 'center'}}>Dr. Efraín <i style={{color: 'black'}}>Gaxiola</i> López</p>
                    <p className="leaderTitle" style={{color: 'white', fontSize: '18px', textAlign: 'center', marginBottom: "30px"}}>Cardiólogo clínico e intervencionista</p>
                    <p style={{fontSize: '13px'}}>
                        Fellowship en Cardiología Intervencionista. College of Medicine, University of Florida. Gainesville,
                        Florida, USA (Entrenamiento especializado de posgrado 3 años) <br/>
                        Cardiología Clínica, Instituto Nacional de Cardiología, “Ignacio Chávez”, CDMX <br/>
                        Director Grupo CETEN (Centro Especializado en Terapia Endovascular) <br/>

                        Director y Fundador de CADECI (Congreso Anual de Cardiología Internacional) <br/>
                        Vicepresidente de la Sociedad Mexicana de Cardiología <br/>
                        Miembro Fundador de SOLACI (Sociedad Latinoamericana de Cardiología Intervencionista). <br/>
                        Ex Presidente de SOCIME (Sociedad de Cardiología Intervencionista de México) <br/>
                        Miembro Honorario de la Sociedad Mexicana de Cardiología y autor de más de 80 publicaciones
                        en revistas médicas de prestigio nacionales e internacionales y autor editor y co-editor de más de 20 <br/>
                        libros <br/>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default LeadersPage;