import { useContext, useState } from "react";
import { Container,Row,Col, Form, Button, Image} from "react-bootstrap";
import ContactModel from "../../models/Contact";
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import { GlobalContext } from "../../context/GlobalContext";
import { IResponseTorak } from "../../interfaces/context/IResponseTorak";

const ContactPage = () => {
    const {getJSON} = useContext(GlobalContext);
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState<ContactModel>(new ContactModel());
    const [success, setSuccess] = useState(false);

    const handleSubmit = () => {
        setSuccess(false);
        
        if (!message.name) {
            alert("Escribe tu nombre");
        }
        else if(!message.email){
            alert("Escribe tu correo");
        }
        else if (!message.message){
            alert("Escribe el mensaje");
        }
        else{
            console.log();
            getJSON("Auth", "SendContactMail", { ...message }).then((response: IResponseTorak) => {
                if(response.status){
                    setMessage(new ContactModel());
                    setSuccess(true);
                }
                setValidated(true);
            });
        }
    };

    return(
        <Container fluid  style={{paddingTop: '85px', backgroundColor: '#e7b219',paddingBottom:'170px'}}>
            <Row>
                <Col>
                    <Image src={grayHeart} className="heart_image_gray"/>
                </Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col md={6} style={{zIndex: '5'}}>
                    <div style={{backgroundColor: 'white', padding: '25px', boxShadow: '1px -2px 2px 0'}}>
                        <Row md={1}>
                            <Col style={{color: 'black', textAlign: 'center',fontWeight: "700",fontSize:'25px',padding:'10px'}}><b>CONTACTO</b></Col>
                        </Row>
                        <Form> 
                            <Form.Group as={Row} className="mb-3" controlId="name">
                                <Form.Label column sm="3">
                                    <b>NOMBRE</b> 
                                </Form.Label>  
                                <Col sm="9">
                                    <Form.Control 
                                        name="name"
                                        className="inputLogin"
                                        type="text"
                                        onChange={(e:any) => {
                                            setMessage({
                                                ...message,
                                                name: e.target.value
                                            })
                                        }}
                                        value={message.name} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu nombre</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="password">
                                <Form.Label column sm="3">
                                    <b>CORREO</b> 
                                </Form.Label>  
                                <Col sm="9">
                                    <Form.Control 
                                        name="correo" 
                                        className="inputLogin"
                                        type="email"
                                        onChange={(e:any) => {
                                            setMessage({
                                                ...message,
                                                email: e.target.value
                                            })
                                        }}
                                        value={message.email} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu correo</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="message">
                                <Form.Label column sm="3">
                                    <b>MENSAJE</b> 
                                </Form.Label>  
                                <Col sm="9">
                                    <Form.Control 
                                        name="mensaje" 
                                        className="inputLogin"
                                        as={'textarea'}
                                        rows={5}
                                        onChange={(e:any) => {
                                            setMessage({
                                                ...message,
                                                message: e.target.value
                                            })
                                        }}
                                        value={message.message} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa un mensaje</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col xs md={12} style={{textAlign: 'center', margin: '20px'}}>
                                        {success && (
                                            <span style={{fontWeight: 'bold', color: 'green'}}>Tu mensaje ha sido enviado, nos pondremos en contácto contigo.</span>
                                        )}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs md={3}>
                                </Col>
                                <Col xs md={6}>
                                    <Button style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b2129', width: '100%'}} onClick={handleSubmit}><b>ENVIAR</b></Button>
                                </Col>
                                <Col xs md={3}></Col>
                            </Row>
                        </Form>
                        
                    </div>
                </Col>
                <Col md={3}></Col>
            </Row>
        </Container>
    )
    
}

export default ContactPage; 