import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import { GlobalContext } from '../../context/GlobalContext';
import useStorage from '../../helpers/useStorage/useStorage';
import { IResponseTorak } from '../../interfaces/context/IResponseTorak';


export const Chapter4 = () => {

    const [show, setShow] = useState(false);
    const [file, setFile] = useState("");
    const [title, setTitle] = useState("");
    const baseUrl = "https://api.cuidatucorazon.com.mx/service/multimedia/getFile?file=";


    const showFile = (name: string, type: "mp4" | "pdf", title: string) => {
        if(type === "mp4"){
            setTitle(title);
            setFile(require("../../assets/multimedia/" + name));
            setShow(true);
        }
    }

    const { getJSON } = useContext(GlobalContext);
    
    const isLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);
    return (
        <Container className="chapter" fluid  style={{paddingTop: '85px', backgroundColor: '#e7b219',paddingBottom:'120px'}}>
            <Container>
                <Row>
                    <Col>
                        <Image src={grayHeart} className="heart_image_gray"/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{display: "table"}}>
                        <Link to="/indexmedia" title='regresar'>
                            <span style={{width: "50px", marginRight: '10px'}}>
                                <FontAwesomeIcon style={{color: '#212529', paddingRight: '1px'}} icon={faCirclePlay as IconProp} size="2x"/>
                            </span>
                            <span style={{color: "#FFF", fontWeight: "bold", verticalAlign: "middle", display: "table-cell"}}>
                                Material audiovisual
                            </span>
                        </Link>
                    </Col>
                </Row>
                <Row style={{marginTop: "20px"}}>
                    <Col md={2} xs={12} style={{zIndex: 99}}>
                        <h2 style={{fontWeight: "bold", textAlign: "center"}}>Capítulo <br/> 4</h2>
                    </Col>
                    <Col md={10} xs={12} className="chapterText" style={{zIndex: 99}}>
                        <h4>Si estoy enfermo del corazón ¿qué síntomas puedo tener? y ¿qué estudios se hacen?</h4>
                        <span>Persona que por discutir le duele el pecho <a href="#" onClick={() => showFile("C4_V1.mp4", "mp4", `Persona que por discutir le duele el pecho`)} className="chapterFile">Video 1</a></span>
                        <span>Persona que al subir escalera tiene un síncope <a href="#" onClick={() => showFile("C4_V2-1.mp4", "mp4", `Persona que al subir escalera tiene un síncope`)} className="chapterFile">Video 2</a></span>
                        <span>Paciente que por disnea usa varias almohadas para dormir <a href="#" onClick={() => showFile("C4_V3.mp4", "mp4", `Paciente que por disnea usa varias almohadas para dormir`)} className="chapterFile">Video 3</a></span>
                        <span>Paciente con disnea paroxística <a href="#" onClick={() => showFile("C4_V4.mp4", "mp4", `Paciente con disnea paroxística`)} className="chapterFile">Video 4</a></span>
                        <span>Ecocardiograma normal <a href="#" onClick={() => showFile("C4_V5.mp4", "mp4", `Ecocardiograma normal`)} className="chapterFile">Video 5</a></span>
                        <span>Paciente realizando prueba de esfuerzo <a href="#" onClick={() => showFile("C4_V6.mp4", "mp4", `Paciente realizando prueba de esfuerzo`)} className="chapterFile">Video 6</a></span>
                        <span>Angiotac coronario <a href="#" onClick={() => showFile("C4_V7-1.mp4", "mp4", `Angiotac coronario`)} className="chapterFile">Video 7</a></span>
                        <span>Ruidos cardiacos normales <a href="#" onClick={() => showFile("C4_V8-1.mp4", "mp4", `Ruidos cardiacos normales`)} className="chapterFile">Video 8</a></span>
                        <span>Soplo por una alteración valvular <a href="#" onClick={() => showFile("C4_V9-1.mp4", "mp4", `Soplo por una alteración valvular`)} className="chapterFile">Video 9</a></span>
                    </Col>
                </Row>
            </Container>
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <video src={file} controls controlsList="nodownload" style={{height: "100%", width: "100%"}} autoPlay>
                    Your browser does not support the video tag.
                </video>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
   </Container>
    );
}