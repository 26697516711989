import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-bootstrap/Carousel';
import redheart from '../../assets/images/corazon_cafe_transparente.png';
import homePhrase from '../../assets/images/home_phrase.png';
import slide1 from '../../assets/images/Slide1.jpg';
import slide2 from '../../assets/images/Slide2.jpg';
import slide3 from '../../assets/images/Slide3.jpg';
import slide4 from '../../assets/images/Slide4.jpg';
import { Link } from "react-router-dom";

export const HomePage = () =>{   
    const myCSS = `
        body{
            background-color: #FFF!important
        }
    `;

    return(
        <>
        <style>{myCSS}</style>
        <Container fluid style={{paddingTop: '55px', minHeight: '100%'}}>
            <Row style={{minHeight: '600px'}}>
                <Col xs={12} md={12} lg={5} style={{backgroundColor: 'white'}}>
                   
                    <Row>
                        <Col>
                            <Image src={redheart} className="heart_image" />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Image src={homePhrase} className="homephrase"/>
                        </Col>
                    </Row>

                    <Row xs={1} md={1} style={{paddingTop: '20px'}}>
                        <Col>
                            <div style={{paddingLeft: '35px', marginBottom: '20px'}}>
                                <Link to="/presentation" className="button-home">LEER MÁS</Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs md={12} lg={7} className="yellowBlock" style={{backgroundColor: '#e7b219', paddingLeft:'10px'}}>
                    <Row style={{backgroundColor: '#e7b219'}}>
                        <Col md={{span: 12}}>
                            <Carousel style={{paddingTop: '30px'}}>
                                <Carousel.Item style={{textAlign: 'center'}}>
                                   <video width="90%" style={{margin: 'auto'}} controls controlsList="nodownload">
                                        <source src={require("../../assets/multimedia/corazon.mp4")}/>
                                   </video>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image
                                        className="d-block"
                                        src={slide1}
                                        alt="First slide"
                                        style={{height: '400px', margin: 'auto'}}
                                        fluid
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image
                                        className="d-block"
                                        src={slide2}
                                        alt="Second slide"
                                        style={{height: '400px', margin: 'auto'}}
                                        fluid
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image
                                    className="d-block"
                                    src={slide3}
                                    alt="Third slide"
                                    style={{height: '400px', margin: 'auto'}}
                                    fluid
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image
                                    className="d-block w-100"
                                    src={slide4}
                                    alt="Fourth slide"
                                    style={{height: '400px', margin: 'auto'}}
                                    fluid
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: '#e7b219'}}>
                        <Col xs md={{span: 12}} style={{paddingBottom: '132px'}}>
                            <ul style={{maxWidth: '100%', width: '460px', margin: 'auto', padding: 0}}>
                                <li style={{display: 'inline-block', paddingLeft: '20px', fontSize: '13px'}}> Dr. Jorge <b>Cossío</b></li>
                                <li className="bulletHome" style={{display: 'inline-block', paddingLeft: '20px', fontSize: '13px'}}><FontAwesomeIcon style={{fontSize: '0.5em'}} icon={faCircle} /></li>
                                <li style={{display: 'inline-block', paddingLeft: '20px', fontSize: '13px'}}> Dr. Efraín <b>Gaxiola</b></li>
                                <li className="bulletHome" style={{display: 'inline-block', paddingLeft: '20px', fontSize: '13px'}}><FontAwesomeIcon style={{fontSize: '0.5em'}} icon={faCircle} /></li>
                                <li style={{display: 'inline-block', paddingLeft: '20px', fontSize: '13px'}}> Dr. Eugenio <b>Ruesga</b></li>
                            </ul>
                        </Col>
                    </Row>
               
                </Col>
            </Row>
        </Container>
    </>
    )
}

