import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import useStorage from "../../helpers/useStorage/useStorage";
import { IResponseTorak } from "../../interfaces/context/IResponseTorak";



const BookPage = () => {
    const {getGlobalState, getJSON} = useContext(GlobalContext);
    const { innerHeight: height } = window;

    const myCSS = `
        .footerBar{
            display: none
        }
        
        .divP{
            padding-bottom: 0
        }
    `;

    const {removeItem} = useStorage();
    
    const isLogged = () =>{
        getJSON("Default", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);
    return(
        <>
            <style>{myCSS}</style>
            {/* <iframe style={{marginTop: 60}} width={'100%'} height={height - 60} src={`https://cuidatucorazon.com.mx/api/web/default?token=${getGlobalState.token}`} title="Libro"></iframe> */}
            <iframe style={{marginTop: 60}} width={'100%'} height={height - 60} src={"https://cuidatucorazon.com.mx/libros_digitales/Flip22OK/index.html"} title="Libro"></iframe>
        </>           
    )
}

export default BookPage;