import React, { useContext, useState } from "react";
import { Container, Row, Col, Form, Button, Image} from "react-bootstrap"
import RegisterModel from "../../models/Register";
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import { GlobalContext } from "../../context/GlobalContext";
import { IResponseTorak } from "../../interfaces/context/IResponseTorak";
import { useNavigate } from "react-router-dom";
 
 const RegisterPage = () => {
    const [register, setRegister]= React.useState<RegisterModel> (new RegisterModel());
    const [validated, setValidated] = React.useState(false);
    const { getJSON } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();
        
        if (form.checkValidity()) {
            setError("");
            getJSON("Auth", "Register", register).then((response: IResponseTorak) => {
                if(response.status){
                    navigate("success/" + response.data);
                }
                else{
                    setError(response.error);
                }
            });
        }

        setValidated(true);
    };
    return(
        <Container fluid  style={{paddingTop: '85px', backgroundColor: '#e7b219',paddingBottom:'120px'}}>
            <Row>
                <Col>
                    <Image src={grayHeart} className="heart_image_gray"/>
                </Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col md={6} style={{zIndex: '5'}}>
                    <div style={{backgroundColor: 'white', padding: '35px', boxShadow: '1px -2px 2px 0'}}>
                        <Row md={1}>
                            <Col style={{color: 'black', textAlign: 'center',fontWeight: "700",fontSize:'25px',padding:'10px'}}>REGISTRO</Col>
                        </Row>
                        <Form autoComplete="off" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Control type="hidden" value={"email@email.com"} />
                            <Form.Control type="hidden" autoComplete="password" value={"Some Password"} />
                            <Form.Group as={Row} className="mb-3" controlId="name">
                                <Form.Label column sm="4">
                                <b>NOMBRE</b> 
                                </Form.Label>  
                                <Col sm="8">
                                    <Form.Control 
                                        name="name"
                                        className="inputRegister"
                                        autoComplete="off"
                                        type="text"
                                        onChange={(e:any) => {
                                            setRegister({
                                                ...register,
                                                name: e.target.value
                                            })
                                        }}
                                        value={register.name} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu nombre</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="email">
                                <Form.Label column sm="4">
                                <b>CORREO</b>  
                                </Form.Label>  
                                <Col sm="8">
                                    <Form.Control 
                                        name="correo" 
                                        className="inputRegister"
                                        autoComplete="off"
                                        type="email"
                                        onChange={(e:any) => {
                                            setRegister({
                                                ...register,
                                                email: e.target.value
                                            })
                                        }}
                                        value={register.email} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu correo</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="password">
                                <Form.Label column sm="4">
                                    <b>CONTRASEÑA</b> 
                                </Form.Label>  
                                <Col sm="8">
                                    <Form.Control 
                                        name="password"
                                        className="inputRegister"
                                        type="password"
                                        autoComplete="off"
                                        value={register.password} 
                                            onChange={(e:any)=> {
                                                setRegister({
                                                    ...register,
                                                    password: e.target.value
                                                })
                                            }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu contraseña</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="confirm">
                                <Form.Label column sm="4">
                                    <b>CONFIRMACIÓN DE CONTRASEÑA</b> 
                                </Form.Label>  
                                <Col sm="8">
                                    <Form.Control 
                                        name="confirm"
                                        className="inputRegister"
                                        type="password"
                                        autoComplete="off"
                                        value={register.confirm} 
                                            onChange={(e:any)=> {
                                                setRegister({
                                                    ...register,
                                                    confirm: e.target.value
                                                })
                                            }}
                                        required
                                        isInvalid={register.password !== register.confirm && register.confirm !== ""}
                                    />
                                    <Form.Control.Feedback type="invalid">La confirmación de contraseña debe ser igual a la contraseña</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="code">
                                <Form.Label column sm="4">
                                    <b>CÓDIGO  LIBRO</b> 
                                </Form.Label>  
                                <Col sm="8">
                                    <Form.Control 
                                        name="code"
                                        className="inputRegister"
                                        autoComplete="off"
                                        type="text"
                                        value={register.code} 
                                            onChange={(e:any)=> {
                                                let code: string = e.target.value;
                                                setRegister({
                                                    ...register,
                                                    code: code.toUpperCase()
                                                })
                                            }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu código</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col md={12}>
                                    <span style={{color: "red", width: "100%", textAlign: "center", display: "block"}}>{error}</span>
                                </Col>
                            </Row>
                            <Row style={{padding:'30px'}}> 
                                <Col xs md={3}></Col>
                                <Col xs md={6}>
                                    <Button style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}type='submit'><b>REGISTRAR</b></Button>
                                </Col>
                                <Col xs md={3}></Col>
                            </Row>
                        </Form>
                        
                    </div>
                </Col>
                <Col md={3}></Col>
            </Row>
    </Container>
    )
 }

 export default RegisterPage;