import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import useStorage from "../../helpers/useStorage/useStorage";
import { IResponseTorak } from "../../interfaces/context/IResponseTorak";

export const Chapter12 = () => {

    const { getJSON } = useContext(GlobalContext);

    const isLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isLogged();
    },[]);
    
    return (
        <div></div>
    );
}