import { faBars, faBook, faPlayCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef } from 'react';
import { Col, Container, Dropdown, Nav, Navbar, Row, Stack } from 'react-bootstrap';
import {Routes, Route, Link, NavLink, useNavigate} from 'react-router-dom';
// import { GlobalContext } from '../context/GlobalContext';
import useStorage from '../helpers/useStorage/useStorage';
import { HomePage } from '../pages/home/home';
import { LoginPage } from '../pages/login/login';
import { useLocation } from 'react-router-dom';
import ContactPage from '../pages/contact/contact';
import LeadersPage from '../pages/leaders/leaders';
import RegisterPage from '../pages/register/register';
import PresentationPage from '../pages/presentation/presentation';
import IndexMediaPage from '../pages/index/indexMedia';
import { GlobalContext } from '../context/GlobalContext';
import SuccessPage from '../pages/register/success';
import RecoveryPasswordPage from '../pages/recovery/recoveryPassword';
import { Chapter1 } from '../pages/chapters/chapter1';
import { Chapter2 } from '../pages/chapters/chapter2';
import { Chapter3 } from '../pages/chapters/chapter3';
import { Chapter4 } from '../pages/chapters/chapter4';
import { Chapter5 } from '../pages/chapters/chapter5';
import { Chapter6 } from '../pages/chapters/chapter6';
import { Chapter8 } from '../pages/chapters/chapter8';
import { Chapter9 } from '../pages/chapters/chapter9';
import { Chapter10 } from '../pages/chapters/chapter10';
import { Chapter11 } from '../pages/chapters/chapter11';
import { Chapter12 } from '../pages/chapters/chapter12';
import { Chapter7 } from '../pages/chapters/chapter7';
import BookPage from '../pages/book/book';
import { IResponseTorak } from '../interfaces/context/IResponseTorak';

export const Template = () =>{
    const {getGlobalState, getJSON} = useContext(GlobalContext);
    const {removeItem} = useStorage();
    const location = useLocation();
    const [homeRender, setHomeRender] = React.useState(0);
    const [isLogged, setIsLogged] = React.useState(false);
    const navigate = useNavigate();
    const ref = useRef(null);

    const logout = () => {
        getJSON("Auth", "Logout", {}).then((response: IResponseTorak) => {
            if(response.status){
                removeItem("globalState");
                setIsLogged(false);
                navigate('/home');
            }
        });
    }

    const changePath = () => {
        if(location.pathname === '/home' || location.pathname === '/'){
            setHomeRender(0);
        }else if(location.pathname === '/leaders'){ 
            setHomeRender(2);
        }else{
            setHomeRender(2);
        }
       
    }

    const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
        <a
          href="/home"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
            <span style={{padding: '6px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '1.5em', backgroundColor: homeRender <= 1 ? '#e7b219' :'white', color: homeRender <= 1 ? 'white' : '#e7b219'}}><FontAwesomeIcon icon={faBars}  /></span>
            <span style={{color: homeRender <= 1 ? 'black' : 'white', paddingLeft: '6px'}}>
                <b>
                    {children}
                </b>
            </span>
        </a>
      ));

    const onClickMenuP = () => {
        //@ts-ignore 
        ref.current.click() 
    }

    useEffect(() => {
        changePath();
    }, [location]);

    useEffect(() => {
        if(getGlobalState.token.trim() !== ""){
            setIsLogged(true);
        }
    },[getGlobalState]);
   

    return (
        <>
            <Container fluid >
                <Row className="rowMenu" style={{ position: 'fixed', left: '0', right: '0', zIndex: '1030'}} >
                    {homeRender === 0 && (
                        <>
                            <Col xs md={5} style={{height: '60px', backgroundColor: 'white', opacity: '0'}}>
                            </Col>
                            <Col xs md={7} style={{height: '60px', backgroundColor: '#e7b219'}}>
                            </Col>
                        </>
                    )}

                    {homeRender !== 0 && (
                        <>
                            <Col xs md={5} style={{height: '60px', backgroundColor: '#e7b219'}}>
                            </Col>
                            <Col xs md={7} style={{height: '60px', backgroundColor: '#e7b219'}}>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    <Navbar expand={"lg"} className="navBarFixed" fixed='top'>
                        <Container fluid style={{paddingTop: '10px'}}>
                            <Dropdown>
                                  <Dropdown.Toggle id="dropdown-menu" as={CustomToggle}>
                                      LA OBRA
                                  </Dropdown.Toggle>
  
                                  <Dropdown.Menu style={{backgroundColor: homeRender ? '#e7b219' : 'white'}}>
                                      <Dropdown.Item href="#"><Link className={homeRender ? 'item-home' : 'item-else'} to="/presentation">PRESENTACIÓN</Link></Dropdown.Item>
                                      <Dropdown.Item href="#"><Link className={homeRender ? 'item-home' : 'item-else'} to="/leaders">COAUTORES </Link></Dropdown.Item>
                                      <Dropdown.Item href="#"><Link className={homeRender ? 'item-home' : 'item-else'} to="/indexmedia">MATERIAL AUDIOVISUAL</Link></Dropdown.Item>
                                  </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown style={{position: 'absolute', right: '10px'}} id="menuP">
                                <Dropdown.Toggle id="dropdown-menu-2" style={{backgroundColor: 'rgb(231, 178, 25)', borderColor: '#FFF'}} ref={ref}>
                                        Menú
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{backgroundColor: homeRender <= 1 ? '#e7b219' : 'white'}} >
                                    
                                    <Link className="item-home dropdown-item" to="/home" onClick={onClickMenuP}>INICIO</Link>
                                    {!isLogged && (
                                        <>
                                            <Link className="item-home dropdown-item" to="/register" onClick={onClickMenuP}>REGISTRO</Link>
                                            <Link className="item-home dropdown-item" to="/login" onClick={onClickMenuP}>INICIO DE SESIÓN</Link>
                                        </>
                                    )}
                                    {isLogged &&(
                                        <Link onClick={()=> {logout(); onClickMenuP();}} className="item-home dropdown-item" to="/">CERRAR SESIÓN</Link>
                                    )}
                                   
                                    <Link className="item-home dropdown-item" to="/contact" onClick={onClickMenuP}>CONTACTO</Link>
                                    
                                </Dropdown.Menu>
                            </Dropdown>
                            <Navbar expand="lg" style={{position: 'absolute', right: '50px', top: '20px'}}>
                                <Navbar.Collapse id="navBarHeader" className="justify-content-end" >
                                    <Nav
                                        activeKey="/home"
                                    >
                                        <Nav.Item >
                                            <NavLink to="/home" className={`${homeRender === 1 ? 'navLink-1' : 'navLink'}`}>
                                                INICIO
                                            </NavLink>
                                        </Nav.Item>

                                        {!isLogged && (
                                            <Nav.Item >
                                                <NavLink to="/register" className={`${homeRender === 1 ? 'navLink-1' : 'navLink'} `}>
                                                    REGISTRO
                                                </NavLink>
                                            </Nav.Item>
                                        )}

                                        {!isLogged &&(
                                            <Nav.Item >
                                                <NavLink to="/login" className={`${homeRender === 1 ? 'navLink-1' : 'navLink'}`} >
                                                    INICIO DE SESIÓN
                                                </NavLink>
                                            </Nav.Item>
                                        )}

                                        {isLogged && (
                                            <Nav.Item >
                                                <a href='/' className={`${homeRender === 1 ? 'navLink-1' : 'navLink'} `}>
                                                    Hola, {getGlobalState.displayName}
                                                </a>
                                            </Nav.Item>
                                        )}
                                        {isLogged &&(
                                            <Nav.Item >
                                                <a href='#' onClick={()=> logout()} className={`${homeRender === 1 ? 'navLink-1' : 'navLink'} `}>CERRAR SESIÓN</a>
                                            </Nav.Item>
                                        )}

                                        <Nav.Item >
                                            <NavLink to="/contact" className={`${homeRender === 1 ? 'navLink-1' : 'navLink'} `}>
                                                CONTACTO
                                            </NavLink>
                                        </Nav.Item>
                                        
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                            
                        </Container>
                    </Navbar>
                </Row>
            </Container>
            
            <div className="divP" >
                <Routes>           
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage/>} />
                    <Route path="/login" element={<LoginPage/>} />
                    <Route path="/contact" element={<ContactPage/>} />
                    <Route path="/register" element={<RegisterPage/>} />
                    <Route path="/register" element={<RegisterPage/>} />
                    <Route path="/register/success/:id" element={<SuccessPage/>} /> 
                    <Route path="/leaders" element={<LeadersPage/>} />
                    <Route path="/presentation" element={<PresentationPage/>} />
                    <Route path="/indexmedia" element={<IndexMediaPage/>} />
                    <Route path="/recoverypassword" element={<RecoveryPasswordPage />} />
                    <Route path="/audiovisual/cap1" element={<Chapter1 />} />
                    <Route path="/audiovisual/cap2" element={<Chapter2 />} />
                    <Route path="/audiovisual/cap3" element={<Chapter3 />} />
                    <Route path="/audiovisual/cap4" element={<Chapter4 />} />
                    <Route path="/audiovisual/cap5" element={<Chapter5 />} />
                    <Route path="/audiovisual/cap6" element={<Chapter6 />} />
                    <Route path="/audiovisual/cap7" element={<Chapter7 />} />
                    <Route path="/audiovisual/cap8" element={<Chapter8 />} />
                    <Route path="/audiovisual/cap9" element={<Chapter9 />} />
                    <Route path="/audiovisual/cap10" element={<Chapter10 />} />
                    <Route path="/audiovisual/cap11" element={<Chapter11 />} />
                    <Route path="/audiovisual/cap11" element={<Chapter12 />} />
                    <Route path="/book" element={<BookPage />} />
                </Routes>
            </div>

            <Container fluid style={{paddingTop: '20px', position: 'fixed', bottom: '0', zIndex: '1000'}} className="footerBar">
                <Row>
                    <Col lg={1} md={0}></Col>
                    <Col lg={10} md={12} style={{boxShadow: '0px -3px 10px 0px'}}>
                        <Row className="footerBlocks">
                            <Col md={3} className="hideMovil" xs={12} style={{backgroundColor: '#e7b219', textAlign: 'center', display: 'flex'}}>
                                <div style={{padding: '20px', justifyContent: 'center', margin: 'auto'}}>
                                    <Link to="indexmedia">
                                        <FontAwesomeIcon style={{fontSize: '2.5em', marginBottom: '10px'}} icon={faPlayCircle} color="rgb(108, 102, 0)"/><br/>
                                        <span style={{color: 'white', fontWeight: 'bold', fontSize: '13px'}}>MATERIAL AUDIOVISUAL</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={3} className="hideMovil" xs={12} style={{backgroundColor: '#9c9a9d', textAlign: 'center', display: 'flex'}}>
                                <div style={{padding: '20px', justifyContent: 'center', margin: 'auto'}}>
                                    <Link to="/leaders">
                                        <FontAwesomeIcon style={{fontSize: '2.5em', marginBottom: '10px'}} icon={faUsers} color="rgb(103, 102, 104)"/><br/>
                                        <span style={{color: 'white', fontWeight: 'bold', fontSize: '13px'}}>COAUTORES</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={6} xs={12} style={{backgroundColor: '#150300', textAlign: 'center', display: 'flex'}}>
                                <div style={{padding: '20px', justifyContent: 'center', margin: 'auto'}}>
                                    <Link to={isLogged ? '/book' : '/login'} title='Libro Digital'>
                                        <FontAwesomeIcon style={{fontSize: '2.5em', marginBottom: '10px'}} icon={faBook} color="rgb(79, 79, 79)"/><br/>
                                        <span style={{color: 'white', fontWeight: 'bold', fontSize: '13px'}}>LIBRO DIGITAL</span><br/>
                                        <span style={{color: 'white', fontSize: '9px'}}>Consulte a su médico - Prohibida su venta - Número de P3 MX2210043465 - Vigencia o límite de entrega 04/10/2024</span>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={1} md={0}></Col>
                </Row>
            </Container>
            
        </>
    )
    
}

export default Template;
