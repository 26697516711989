import { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import { Link } from "react-router-dom";
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import { GlobalContext } from "../../context/GlobalContext";
import useStorage from "../../helpers/useStorage/useStorage";
import { IResponseTorak } from "../../interfaces/context/IResponseTorak";

const IndexMediaPage = () =>{
    
    const {getGlobalState} = useContext(GlobalContext);
    const [isLogged, setIsLogged] = useState(false);

    const { getJSON } = useContext(GlobalContext);

    const isStillLogged = () =>{
        getJSON("Auth", "StillLogged", {});
    }

    useEffect(() => {
        isStillLogged();
    },[]);

    useEffect(() => {
        if(getGlobalState.token.trim() !== ""){
            setIsLogged(true);
        }
        else{
            setIsLogged(false);
        }
    },[getGlobalState.token]);

    return(
        <Container fluid style={{backgroundColor: '#e7b219', paddingTop: '85px', minHeight: '700px'}}>
            <Row>
                <Col>
                    <Image src={grayHeart} className="heart_image_gray"/>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'center', fontSize: '28px', fontWeight: '700'}}><span>ÍNDICE </span></Col>
            </Row>
            <Row xs={1} md={3} lg={4}>
                
                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 1</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>¿Cómo funciona mi corazón? <br/> &nbsp; <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap1"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 2</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>De sentirse bien a estar bien del corazón <br/> &nbsp; <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap2"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>  

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px" , minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 3</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Factores de riesgo y hábitos para tener un corazón sano <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap3"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 4</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Si estoy enfermo del corazón ¿qué síntomas puedo tener? y ¿qué estudios se hacen? <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap4"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 5</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Enfermedades específicas del corazón <br/> &nbsp; <br/> &nbsp;</p>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 6</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>El corazón va rápido, lento o irregular ¿qué es una arritmia cardiaca? Y ¿cómo debe ser tratada?</p>
                        <Link to={"/audiovisual/cap6"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>  

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 7</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Muerte súbita cardiaca en jóvenes, deportistas y gente sana <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap7"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 8</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Entendiendo que son los marcapasos a más de 50 años del primer implante <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap8"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>  

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 9</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Tratamiento: de la pastilla a la inteligencia artificial pasando por el intervencionismo cardiovascular</p>
                        <Link to={"/audiovisual/cap9"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 10</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>¿Cómo debo alimentarme para mantener sano mi corazón? <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap10"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>  

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 11</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>¿Qué ejercicios ayudan a mantener sano y rehabilitar mi corazón? <br/> &nbsp;</p>
                        <Link to={"/audiovisual/cap11"} style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}}>Ver más..</Link>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 12</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Mi hijo tiene cardiopatía congénita <br/> &nbsp; <br/> &nbsp;</p>
                    </div>
                </Col>

                <Col style={{padding: '20px 25px'}}>
                    <div className="mediumSizeMedia" style={{padding: '15px', backgroundColor: 'white', borderRadius: "5px", minHeight: "160px"}}>
                        <p style={{fontSize: '20px', fontWeight: '700', marginBottom: '0px'}}>CAPÍTULO: 13</p>
                        <p className="descriptionMedia" style={{fontSize: '16px', fontWeight: '600', color: '#e7b219', marginBottom: '0px'}}>Realidades y mitos de la COVID-19 <br/> &nbsp; <br/> &nbsp; </p>
                    </div>
                </Col>   
                
            </Row>
        </Container>
    )
}

export default IndexMediaPage;