import { Container, Row, Col, Form, Button, Image} from "react-bootstrap";
import { useContext } from "react";
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { NavLink, useParams } from "react-router-dom";

export const SuccessPage = () => {
    const { getJSON } = useContext(GlobalContext);
    const [bandera, setBandera] = React.useState(false);
    const [code, setCode] = React.useState(undefined);
    const [formValidatedCode, setFormValidateCode] = React.useState(false);
    const [error, setError] = React.useState("");
    let { id } = useParams();
    
    const validateCode = async() =>{
        await getJSON("Auth", "ValidateCode", {id: id, code: code}).then((response : any) => {
            if(response.status){
                setBandera(true);
            }
            else{
                setError(response.error);
            }
        });
    }

    const validateForm = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if(form.checkValidity()) {
            validateCode();
        }

        setFormValidateCode(true);
    }

    return(
        <Container fluid  style={{paddingTop: '85px', backgroundColor: '#e7b219',paddingBottom: '240px', marginTop: "50px"}}>
            <Row>
                <Col>
                    <Image src={grayHeart} className="heart_image_gray"/>
                </Col>
            </Row>
            <Row style={{padding: "10px"}}>
                <Col md={4}>
                </Col>
                
                    {bandera &&(
                        <Col md={4} style={{backgroundColor: '#EBE8EC', borderRadius: '1em', padding: '20px'}}>
                            <Row md={1} xs={1}>
                                <Col>
                                    <p style={{color: 'green', textAlign: 'center', fontWeight: '600'}}>¡Tu cuenta ha sido activada!</p>
                                </Col>
                            </Row>
                            <Row md={1} xs={1}>
                                <Col>
                                    <p style={{textAlign: 'center', fontWeight: '400'}}>Ya puedes iniciar sesión con tus credeciales de registro</p>
                                    <p style={{textAlign: 'center', fontWeight: '500'}}>
                                        <NavLink to={"/login"}><span style={{color: "blue"}}>Ir a inicio de sesión</span></NavLink>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    {!bandera &&(
                        <Col md={4} style={{backgroundColor: '#EBE8EC', borderRadius: '1em', padding: '20px'}}>
                            <Row md={1} xs={1}>
                                <Col>
                                    <p style={{textAlign: 'center', fontWeight: '600'}}>Su registro se ha completado, hemos enviado un código de verificación al correo proporcionado, ingréselo a continuación para iniciar sesión</p>
                                    <p style={{textAlign: 'center', fontWeight: '600'}}> EN OCACIONES EL CORREO DE VERIFICACIÓN LLEGA A BANDEJA DE NO DESEADOS</p>
                                </Col>
                            </Row>
                            <Row md={1} xs={1}>
                                <Col>
                                    <p style={{textAlign: 'center', fontWeight: '500', color: "red"}}>{error}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form noValidate validated={formValidatedCode} onSubmit={validateForm}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Row>
                                                <Col md={2}></Col>
                                                <Col md={8}>
                                                    <Form.Label style={{color: '#ef7953'}}>Ingrese su código de verificación</Form.Label>
                                                    <Form.Control 
                                                        type="number"
                                                        placeholder="0000"
                                                        className="codeInput"
                                                        onChange={(event:any) => {
                                                            if(event.target.value <= 9999){
                                                                setCode(event.target.value);
                                                            }
                                                        }}
                                                        value={code}
                                                        required/>
                                                </Col>
                                                <Col md={2}></Col>
                                            </Row>
                                            <Row style={{marginTop: "15px"}}>
                                                <Col md={3}></Col>
                                                <Col md={6} style={{textAlign: 'center'}}>
                                                    <Button type="submit" style={{backgroundColor: "#000"}}>Validar</Button>
                                                </Col>
                                                <Col md={3}></Col>
                                            </Row>
                                        </Form.Group>
                                        
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    )}
                <Col md={4}></Col>
            </Row>  
        </Container>
    );
}
export default SuccessPage;