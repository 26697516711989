import React, { useContext } from "react";
import './login.css';
import { useNavigate} from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image} from "react-bootstrap";
import userLogo from '../../assets/images/login_ico.png';
import { Link } from "react-router-dom";
import LoginModel from "../../models/Login";
import grayHeart from '../../assets/images/corazon_cafe_transparente.png';
import { GlobalContext } from "../../context/GlobalContext";
import { IAppGlobalState } from "../../interfaces/context/IAppGlobalState";
import useStorage from "../../helpers/useStorage/useStorage";

export const LoginPage = () => {
    const navigate = useNavigate();
    const {setItem} = useStorage();
    const [login,setLogin]=React.useState<LoginModel> (new LoginModel());
    const [error, setError] = React.useState("");
    const {sendData, setGlobalState} = useContext(GlobalContext);
    const [validated, setValidated] = React.useState(false);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        setError('');
        event.preventDefault();
        event.stopPropagation();

        if(form.checkValidity()){
           sendLogin();
        }

        setValidated(true); 
    };

    const sendLogin = () => {
        sendData("Auth", "Login", login).then((response : any) => {
            if(response.status){
                let sessionObject: any = {
                    token: response.data.token,
                    ...response.data.userData
                };

                let globalData: IAppGlobalState = {
                    token: response.data.token,
                    username: response.data.userData.username,
                    displayName: response.data.userData.username,
                    permissions: response.data.userData.permissions,
                    urlPhoto: ''
                }

                setGlobalState(globalData);
                navigate("/home");
            }
            else{
                setError(response.error);
            }
        });
    }

    return(
        <Container fluid  style={{paddingTop: '85px', backgroundColor: '#e7b219',paddingBottom:'120px'}}>
             <Row>
                <Col>
                    <Image src={grayHeart} className="heart_image_gray"/>
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={1}></Col>
                <Col lg={6} md={10} style={{zIndex: '5'}}>
                    <div style={{backgroundColor: 'white', padding: '25px', boxShadow: '1px -2px 2px 0'}}>
                        <Row md={1}>
                            <Col style={{color: 'black', textAlign: 'center',fontWeight: "700",fontSize:'25px',padding:'10px'}}>INICIO DE SESIÓN</Col>
                        </Row>
                        <Row>
                            <Col md={3}></Col>
                            <Col md={6} style={{textAlign:'center',padding:"30px"}}>
                                <Image src={userLogo} width={150} alt="image" fluid />
                                <div style={{color: 'red', textAlign: 'center',fontSize: '14px', display: error !== '' ? 'block' : 'none', marginTop: "15px"}}>{error}</div>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Form noValidate validated={validated} onSubmit={handleSubmit}> 
                            <Form.Group as={Row} className="mb-3" controlId="name">
                                <Form.Label column sm="3">
                                    <b>CORREO</b> 
                                </Form.Label>  
                                <Col sm="9">
                                    <Form.Control 

                                        name="name"
                                        className="inputLogin"
                                        type="email" 
                                        onChange={(e:any) => {
                                            setLogin({
                                                ...login,
                                                username: e.target.value
                                            })
                                        }}
                                        value={login.username} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu correo</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="password">
                                <Form.Label column sm="3">
                                    <b>CONTRASEÑA</b> 
                                </Form.Label>  
                                <Col sm="9">
                                    <Form.Control 
                                        name="password" 
                                        className="inputLogin"
                                        type="password"
                                        value={login.password}
                                        autoComplete="true"
                                        onChange={(e:any)=> {
                                            setLogin({
                                                ...login,
                                                password: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">Ingresa tu contraseña</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Row xs={1} md={1}> 
                                <Col style={{ textAlign:'right',paddingBottom:'10px',fontSize: '12px',color:'black'}}><Link to="/recoverypassword" className='forgotText' >¿Olvidaste la contraseña?</Link></Col> 
                            </Row> 
                            <Row>
                                <Col xs md={3}></Col>
                                <Col xs md={6}>
                                    <Button style={{backgroundColor: ' #e7b219', border: '1px solid  #e7b219', width: '100%'}} type='submit'><b>INICIAR SESIÓN</b></Button>
                                </Col>
                                <Col xs md={3}></Col>
                            
                            </Row>
                        </Form>
                       
                    </div>
                </Col>
                <Col lg={3} md={1}></Col>
            </Row>
            
          
        </Container>
    )
}

